

















































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'


@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors', 'promotions',
      'merchants', 'form', 'cities', 'categories', 'subcategories',
      'nomenclatures', 'suppliers', 'blocked',
    ]),
    ...mapGetters(STORE_KEY, [
      'maxAccuralCount', 'allowAccuralAutoStart', 'allowAccuralManualStart',
      'allowRequireFeedback', 'allowRequireMark', 'allowRequireMerchantFeedback',
      'allowRequireMerchantMark',
    ]),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      name: { required },
      type: { required },
      max_outpays: { required },
      max_daily_outpays: { required },
      min_total_sum: { required },
      max_outpay_sum: { required },
      percent_by_item: {},
      max_products_count: { required },
      description: { required },
      merchant: {
        required: () => {
          // @ts-ignore
          return this.form.supplier_view !== 'merchant' || this.form.merchant
        },
      },
      product: {
        nomenclature: {},
      },
      supplier: {
        required: () => {
          // @ts-ignore
          return this.form.supplier_view !== 'procucer' || this.form.supplier
        },
      },
      supplier_view: {},
      accurals: {
        $each: {
          min_count: { required },
          value: { required },
          value_multiple: { required },
        },
      },
      item_sum: {},
      receipt_sum: {},
      promotion: {
        start_date: { required },
        end_date: { required },
        cities: {},
        conditions: { required },
        images: { required },
        category: { required },
      },
    }
    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
  },
})
export default class CashbackForm extends Vue {

  public isOpen: number = -1
  public showModal: any = false
  public viewFilter: boolean = true
  public form!: any
  public filter!: any
  public blocked!: any
  public allowAccuralAutoStart!: any
  public allowAccuralManualStart!: any
  public maxAccuralCount!: any
  public selectedOptions: any = []
  public selectedOptionsCat: any = []
  public selectedCategory: any = []
  public nextName: any = ''
  public prod: any = []
  public productItem: any = []
  public productIndex: any = null
  public nextId: any = 0
  public nextPrice: any = '0'
  public nextPriceNew: any = '0'
  public nextNomenclature: any = null
  public show: any = false
  public showItem: any = false
  public dropFiles: any[] = []
  public productDropFile: any = null
  public productDropFilesId: any = null
  public subCattegories: any = []
  public activeTab: number = 0
  public accuralType: string = 'percent'
  public cashbackType: string = 'by_percent'
  public cashbackTypeWithItem: boolean = false
  public supplier_views: any[] = [
    { text: 'Магазин', value: 'merchant' },
    { text: 'Производитель', value: 'producer' },
  ]

  public accural_types: any = {
    by_receipt: { name: 'процент/рубль от чека' },
    by_receipt_item_with_promotion: { name: 'процент/рубль от стоимости акционного товара' },
    by_receipt_with_promotion: { name: 'процент/рубль от чека с акционным товаром' },
  }

  get accuralTypesList() {
    return Object.keys(this.accural_types).map((k) => {
      const t: any = this.accural_types[k]
      return {
        name: t.name,
        value: k,
      }
    })
  }

  get isNew() {
    return !Number.isInteger(this.$route.params.id)
  }

  get titleStack() {
    return [
      'Кэшбэк', (this.isNew ? 'Создание программы' : 'Редактирование программы'),
    ]
  }

  @Watch('cashbackType')
  public changedCashbackType() {
    this.setCashbackType()
  }

  @Watch('maxAccuralCount')
  public changedMaxAccuralCount() {
    this.form.max_products_count = this.maxAccuralCount
  }

  @Watch('form.supplier_view')
  public changedSupplierView() {
    if (this.form.supplier_view === 'producer') {
      this.cashbackType = 'by_receipt_item'
      this.setCashbackType()
    }
  }

  @Watch('form.merchant')
  @Watch('form.supplier')
  public changedAllowAccuralAutoStart() {
    if (!this.allowAccuralAutoStart && this.allowAccuralManualStart) {
      this.form.accural_start = 'manual'
    }
    else if (this.allowAccuralAutoStart && !this.allowAccuralManualStart) {
      this.form.accural_start = 'auto'
    }
  }

  @Watch('cashbackTypeWithItem')
  public changedCashbackTypeWithItem() {
    this.setCashbackType()
  }

  @Watch('accuralType')
  public changedAccuralType() {
    this.setAccuralTypes()
  }

  @Watch('activeTab')
  public changedActiveTab() {
    this.form.many_products = (this.activeTab === 1)
  }

  public changeType() {
    this.form.many_products = this.form.type === 'by_receipt_with_item'
    this.activeTab = 0
    if (this.form.type === 'by_receipt') {
      this.accuralType = 'percent'
      this.setAccuralTypes()
    }
  }

  public checkIntersection() {
    this.$store.dispatch(`${STORE_KEY}/checkIntersection`)
  }

  public addNewAccural() {
    this.$store.dispatch(`${STORE_KEY}/addNewAccural`, this.accuralType)
  }

  public addNewNomenclature() {
    window.open('/nomenclatures/new', '_blank')
  }

  public openNomenclatureImage(nomenclature: any) {
    console.log(nomenclature)
    if (nomenclature.product_image && nomenclature.product_image.url) {
      window.open(nomenclature.product_image.url)
    }
  }

  public delNewAccural(item: any) {
    this.$store.dispatch(`${STORE_KEY}/delNewAccural`, item)
  }

  public changeValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  public setAccuralTypes() {
    const _type = this.accuralType
    this.form.accurals.map((item: any) => {
      item.type = _type
    })
    // @ts-ignore
    this.$v.form['accurals'].$touch()
  }

  public setCashbackType() {
    const _type = this.cashbackType
    const _with_item = this.cashbackTypeWithItem
    if (_type === 'by_receipt_item') {
      this.form.type = 'by_receipt_item'
    }
    else if (_type === 'by_receipt' && _with_item) {
      this.form.type = 'by_receipt_with_item'
    }
    else {
      this.form.type = 'by_receipt'
    }
    // this.form.accurals.map((item: any) => {
    //   item.type = _type;
    // })
    // // @ts-ignore
    // this.$v.form['accurals'].$touch();
  }

  public changeValuePromotion(field: string, value: any) {
    // @ts-ignore
    this.$v.form.promotion[field].$touch()
    this.checkIntersection()
  }

  public changeValueProduct(field: string, value: any) {
    // @ts-ignore
    this.$v.form.product[field].$touch()
    this.checkIntersection()
  }

  public uploadFile(files: any[]) {
    files.forEach((file: any) => {
      const formData = new FormData()
      formData.append('file', file)
      // @ts-ignore
      this.$http
        .post(`/admins/rest/v1/uploads`, formData, {})
        .then((response: any) => {
          this.$store.dispatch(
            `${STORE_KEY}/addAttachmentsPromotion`,
            response.data.upload,
          )
          this.dropFiles.push(response.data.upload)
        })
    })

    // @ts-ignore
    this.$v.form.promotion.images.$touch()
  }

  public nomenclatureSearch(search: any, loading: any) {
    loading(true)
    this.$store.dispatch(`${STORE_KEY}/nomenclatureSearch`, { term: search }).then(() => {
      loading(false)
    })
  }


  public submitObject() {
    this.$v.form.$touch()

    if (!this.$v.form.$error) {
      this.$store.dispatch(`${STORE_KEY}/saveObject`, this.$route.params.id)
        .then(() => {
          this.$buefy.snackbar.open(`Данные успешно сохранены`)
          this.$router.push({ name: 'cashback-list' })
        })
        .catch((e) => {
          console.log(1, e)
          const msg = `При сохраненит возникли проблемы. Попробуйте позже.`
          this.$buefy.snackbar.open({
            type: 'is-warning',
            message: msg,
          })
        })
    }
  }

  public fieldsValue(field: string, subfield: string) {
    this.checkIntersection()
    if (this.$v.form[field]) {
      // @ts-ignore
      this.$v.form[field].$touch()
    }
    if (field === 'min_count' && this.maxAccuralCount > this.form.max_products_count) {
      this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
        field: 'max_products_count',
        newValue: this.maxAccuralCount,
      })
    }
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  @Watch('$route.query')
  public handleParamsChange() {
    if (this.$route.params.id) {
      this.$store.dispatch(`${STORE_KEY}/loadData`, this.$route.params)
        .then((result) => {
          console.log(result)
          this.activeTab = result.many_products ? 1 : 0
          this.accuralType = result.accurals.length ? result.accurals[0].type : null
          this.cashbackType = result.type === 'by_receipt_with_item' || result.type === 'by_receipt' ? 'by_receipt' : result.type
          this.cashbackTypeWithItem = result.type === 'by_receipt_with_item'
        })
    }
    else {
      this.$store.dispatch(`${STORE_KEY}/prepareNew`)
        .then((result) => {
          // nothing
        })
    }
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
