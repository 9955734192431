import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'

interface BonusFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;
  filering: boolean;

  blocked: boolean;
  cashback: any;
  merchants: any[];
  promotions: any[];
  form: any;
  cities: any[];
  categories: any[];
  subcategories: any[];
  products_list: any[];
  nomenclatures: any[];
  suppliers: any[];
}

const $state: BonusFormState = {
  loading: false,
  loaded: false,
  filering: false,
  fatalError: false,
  serverErrors: null,

  blocked: false,
  cashback: null,
  merchants: [],
  promotions: [],
  form: {},
  cities: [],
  categories: [],
  subcategories: [],
  products_list: [],
  nomenclatures: [],
  suppliers: [],
}


const initState = cloneDeep($state)

const EMPTY_OBJECT = {
  name: '',
  type: 'by_receipt_item',
  many_products: false,
  product: {},
  promotion: {
    start_date: null,
    end_date: null,
    cities: [],
    conditions: null,
    images: [],
    category: null,
  },
  description: null,
  merchant: null,
  supplier: null,
  accurals: [],
  min_merchant_mark: 0,
  min_mark: 0,
  min_feedback: 0,
  min_merchant_feedback: 0,
  accural_start: 'auto',
  max_outpays: 1,
  max_daily_outpays: 1,
  min_total_sum: 0,
  max_outpay_sum: 0,
  percent_by_item: 0,
  max_products_count: 0,
  supplier_view: 'merchant',
  item_sum: 0,
  receipt_sum: 0,

}
const EMPTY_ACCURAL_OBJECT = {
  accural_type: 'by_receipt_item_with_promotion',
  type: 'percent',
  value: 0,
  value_multiple: 0,
  min_count: 1,
  products: [],
}
const EMPTY_PRODUCT_OBJECT = {
  nomenclature: null,
  price_current: '0',
  price_new: '0',
}

const AVAILABLE_FIELDS = [
  'promotion', 'description', 'merchant', 'min_merchant_mark', 'type',
  'min_merchant_feedback', 'min_mark', 'min_feedback', 'accural_start',
  'supplier', 'category', 'name', 'accurals', 'with_all_accurals',
  'max_daily_outpays', 'max_outpays', 'min_total_sum', 'max_outpay_sum',
  'many_products', 'product', 'percent_by_item', 'max_products_count',
  'supplier_view', 'item_sum', 'receipt_sum', 'require_feedback',
  'require_mark', 'require_merchant_feedback', 'require_merchant_mark',
]

export const $actions: ActionTree<BonusFormState, RootState> = {
  preloadData({ commit }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      Promise.all([
        vm.$http.get('/admins/rest/v1/promotion'),
        vm.$http.get('/admins/rest/v1/merchants', { params: { per_page: 1000 } }),
        vm.$http.get(`/admins/rest/v1/cities`, { params: { per_page: 1000 } }),
        vm.$http.get('/admins/rest/v1/product_categories'),
        vm.$http.get('/admins/rest/v1/suppliers'),
      ])
        .then(([promirionsResp, merchantsResp, responseCities, responseCategories, responseSuppliers]) => {
          commit('SET_PROMOTIONS', promirionsResp.data)
          commit('SET_MERCHANTS', merchantsResp.data)
          commit('SET_CITIES', responseCities.data)
          commit('SET_CATEGORIES_LIST', responseCategories.data)
          commit('SET_SUPPLIERS_LIST', responseSuppliers.data)
          resolve(null)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  prepareNew({ commit, state, dispatch }, predefined) {
    return new Promise((resolve, reject) => {
      dispatch('preloadData').then((() => {
        commit('SET_FORM_DATA', Object.assign({}, EMPTY_OBJECT))
        commit('LOADED_SUCCESS')
        resolve(state.form)
      }))
    })
  },
  loadData({ commit, state, dispatch }, params) {
    const vm = (this as any)._vm
    commit('START_LOADING')
    return new Promise((resolve, reject) => {
      dispatch('preloadData').then((() => {
        if (params.id > 0) {
          Promise.all([
            vm.$http.get('/admins/rest/v1/cashbacks/' + params.id),
          ])
            .then(([cashbackResp]) => {
              commit('LOADED_SUCCESS')
              commit('SET_FORM_DATA', cashbackResp.data.cashback)
              resolve(cashbackResp.data.cashback)
            })
        }
      }))
    })
  },
  addNewAccural({ state }, type) {
    const _accural: any = cloneDeep(EMPTY_ACCURAL_OBJECT)
    _accural.products.push(cloneDeep(EMPTY_PRODUCT_OBJECT))
    _accural.type = type
    state.form.accurals.push(_accural)
  },
  delNewAccural({ state }, item) {
    state.form.accurals.pop(item)
  },

  addAccuralProduct({ state }, { index, product }) {
    state.form.accurals[index].products.push(product)
  },

  nomenclatureSearch({ state, commit }, { term }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm
      Promise.all([
        vm.$http.get('/admins/rest/v1/nomenclatures', {
          params: {
            name: term,
            parent_category_id: state.form.promotion.category ? state.form.promotion.category.id : null,
            supplier_id: state.form.supplier ? state.form.supplierid : null,
          },
        }),
      ])
        .then(([responseNomenclature]) => {
          commit('SET_NOMENCLATURE_LIST', responseNomenclature.data)
          resolve(null)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  addAttachments({ state, commit }, newValue) {
    commit('CHANGE_VALUE_PRODUCT', { field: 'image_upload_id', newValue: newValue.id })

  },

  addAttachmentsPromotion({ state, commit }, newValue) {
    commit('ADD_ATTACHMENT_PROMOTION', { newValue })

  },

  changeFormValue({ commit }, { field, newValue, index }) {
    commit('CHANGE_VALUE', { field, newValue, index })
  },

  changeFormValuePromotion({ commit }, { field, newValue, index }) {
    commit('CHANGE_VALUE_PROMOTION', { field, newValue, index })
    // commit('REMOVE_PRODUCT', {index});
  },

  changeFormValueProduct({ commit }, { field, newValue, index }) {
    commit('CHANGE_VALUE_PRODUCT', { field, newValue, index })
    // commit('REMOVE_PRODUCT', {index});
  },

  deleteProduct({ commit }, { accrual_index, index }) {
    commit('REMOVE_PRODUCT', { accrual_index, index })
  },
  saveObject({ state, commit }, id) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      console.log(state.form)


      const cleanForm: any = {}
      for (const key of AVAILABLE_FIELDS) {
        cleanForm[key] = state.form[key]
      }
      commit('START_LOADING')

      let request = null

      if (id) {
        request = vm.$http.patch(`/admins/rest/v1/cashbacks/${id}`, {
          cashback: cleanForm,
        })
      }
      else {
        request = vm.$http.post(`/admins/rest/v1/cashbacks`, {
          cashback: cleanForm,
        })
      }

      console.log(cleanForm)


      request
        .then((response: AxiosResponse) => {
          commit('LOADED_SUCCESS')

          resolve(response.data)
        })
        .catch((errorResponse: AxiosError) => {
          // eslint-disable-next-line
          console.error('Fatal error', errorResponse)

          if (errorResponse.response?.status === 500) {
            commit('LOADED_ERROR')
            reject()
          }
          else {
            const serverError = errorResponse.response?.data?.error

            commit('LOADED_SUCCESS')
            commit('SET_SERVER_ERROR', serverError)
            reject(serverError)
          }
        })
    })
  },
  checkIntersection({ state, commit }) {
    const vm = (this as any)._vm
    const object_id = state.form ? state.form.id : null
    const merchant_id = state.form.merchant ? state.form.merchant.id : null
    const supplier_id = state.form.supplier ? state.form.supplier.id : null
    const nomenclature_ids: any[] = []
    if (state.form.many_products && state.form.product && state.form.product.nomenclature) {
      nomenclature_ids.push(state.form.product.nomenclature.id)
    }
    state.form.accurals.map((accural: any) => {
      accural.products.map((product: any) => {
        if (product.nomenclature) {
          nomenclature_ids.push(product.nomenclature.id)
        }
      })
    })
    console.log(nomenclature_ids)
    vm.$http
      .get(`/admins/rest/v1/cashbacks/intersections`, {
        params: {
          cashback_id: object_id,
          merchant_id,
          supplier_id,
          start_date: state.form.promotion.start_date,
          end_date: state.form.promotion.end_date,
          nomenclature_ids: JSON.stringify(nomenclature_ids),
        },
      }).then((response: AxiosResponse) => {
      state.blocked = response.data.intersect
    })
  },
}

const compare: any = ((a: any, b: any) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
})

export const $mutations: MutationTree<BonusFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_FILTER: (state) => {
    state.filering = true
  },
  END_FILTER: (state) => {
    state.filering = false
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },
  SET_PROMOTIONS: (state, { promotions }) => {
    state.promotions = promotions.sort(compare)
  },
  SET_MERCHANTS: (state, { merchants }) => {
    state.merchants = merchants.sort(compare)
  },
  SET_FORM_DATA(state, predefined) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
      ...predefined,
    }
    if (state.form.product === null) {
      state.form.product = {}
    }
    if (predefined.accurals.length) {
      predefined.accurals = predefined.accurals.map((item: any) => {
        if (!item.products.length) {
          item.products.push(cloneDeep(EMPTY_PRODUCT_OBJECT))
        }
        return item
      })
    }
  },
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  },
  SET_CATEGORIES_LIST(state, data) {
    state.categories = data.product_categories.map((item: any) => {
      item._order_key = (item.parent_id ? item.parent_id + '-' : '') + item.id
      if (item.parent_id) {
        item.name = '-- ' + item.name
      }
      return item
    }).sort((a: any, b: any) => {
      return `${a._order_key}`.localeCompare(`${b._order_key}`)
    })
  },
  SET_PRODUCTS_LIST(state, data) {
    state.products_list = data.products
  },
  SET_NOMENCLATURE_LIST(state, data) {
    state.nomenclatures = data.nomenclatures
  },
  SET_SUPPLIERS_LIST(state, data) {
    state.suppliers = data.suppliers
  },

  CHANGE_VALUE(state: BonusFormState, { field, newValue }) {
    if (field === 'value_feedback' || field === 'value_mark' || field === 'start_number') {
      state.form[field] = parseInt(newValue)
    }
    else if (field === 'min_sum') {
      state.form[field] = parseFloat(newValue)
    }
    else {
      state.form[field] = newValue
    }
  },

  CHANGE_VALUE_PRODUCT(state: BonusFormState, { field, newValue, index }) {
    state.form.promotion.products = newValue
  },

  CHANGE_VALUE_PROMOTION(state: BonusFormState, { field, newValue, index }) {
    state.form.promotion[field] = newValue
  },

  ADD_ATTACHMENT_PROMOTION(state: BonusFormState, { newValue }) {
    state.form.promotion.images.push(newValue)
  },
  REMOVE_PRODUCT(state: BonusFormState, { accrual_index, index }) {
    state.form.accurals[accrual_index].products.splice(index, 1)
  },

}

export const $getters: GetterTree<BonusFormState, RootState> = {
  merchantsOptions: (state: any) => {
    return state.merchants.map((item: any) => {
      return {
        text: item.name,
        value: item.id,
      }
    })
  },
  maxAccuralCount: (state: any) => {
    const counts = state.form.accurals.map((item: any) => {
      return item.min_count
    })
    return Math.max.apply(null, counts)
  },
  allowAccuralAutoStart: (state: any) => {
    if (state.form.supplier_view === 'merchant' && state.form.merchant) {
      return state.form.merchant.allow_accural_auto_start
    }
    if (state.form.supplier_view === 'producer' && state.form.supplier) {
      return state.form.supplier.allow_accural_auto_start
    }
    return false
  },
  allowAccuralManualStart: (state: any) => {
    if (state.form.supplier_view === 'merchant' && state.form.merchant) {
      return state.form.merchant.allow_accural_manual_start
    }
    if (state.form.supplier_view === 'producer' && state.form.supplier) {
      return state.form.supplier.allow_accural_manual_start
    }
    return false
  },
  allowRequireFeedback: (state: any) => {
    return state.form.supplier_view === 'producer' && state.form.supplier && state.form.supplier.feedback_product
  },
  allowRequireMark: (state: any) => {
    return state.form.supplier_view === 'producer' && state.form.supplier && state.form.supplier.mark_product
  },
  allowRequireMerchantFeedback: (state: any) => {
    return state.form.supplier_view === 'merchant' && state.form.merchant && state.form.merchant.feedback_merchant
  },
  allowRequireMerchantMark: (state: any) => {
    return state.form.supplier_view === 'merchant' && state.form.merchant && state.form.merchant.mark_merchant
  },
}

export const STORE_KEY = '$_cashback'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
